@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
body {
    overflow: hidden;
}
.col-right-main {
    max-height: 100vh;
    overflow-y: auto;
}
.col-left-main {
    min-height: 100vh;
    overflow-y: auto;
    max-height: 100vh;
}
@media(max-width:767px) {
 body {
    padding-top: 90px;
 }
 .col-right-main{
    padding-bottom: 250px !important;
 }   
}